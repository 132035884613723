import { z } from 'zod'

export const GOOGLE_PROVIDER_NAME = 'google'
export const FACEBOOK_PROVIDER_NAME = 'facebook'

export const providerNames = [
	GOOGLE_PROVIDER_NAME,
	FACEBOOK_PROVIDER_NAME,
] as const
export const ProviderNameSchema = z.enum(providerNames)

export type ProviderName = z.infer<typeof ProviderNameSchema>

export type ProviderAuthResult = {
	success: boolean
	data: {
		id: string
		email: string
		name?: string
		imageUrl?: string
	} | null
	error: string | null
}
